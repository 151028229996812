<template>
  <div>
    <user-list-item v-for="u in users" :key="u.uid" :u="u" />
    <div class="col-12 d-flex my-2 justify-content-center">
      <b-button v-if="last_doc" variant="primary" @click="getUsers">
        Завантажити ще {{ per_page }}
      </b-button>
    </div>
  </div>
</template>

<script>
import userListItem from "@/components/user-list-item.vue";
export default {
  components: {
    userListItem,
  },
  data() {
    return {
      isLoading: false,
      users: [],
      searchQuery: "",
      per_page: 48,
      page: 1,
      last_doc: null,
    };
  },
  methods: {
    async getUsers() {
      this.isLoading = true;
      let args = {
        collectionName: "users",
        where: ["isWorker", "==", true],
        limitBy: this.per_page,
      };
      if (this.page !== 1 && this.last_doc) {
        args["startAfter"] = this.last_doc;
      }
      var result = await this.$db.getCollection(args);
      result.docs.forEach((doc)=>{
        this.users.push(doc);
      })
      this.last_doc = null;
      if (result.docs.length == this.per_page) {
        this.last_doc = result.lastVisible;
      }

      this.page++;
      this.isLoading = false;
    },
  },
  mounted() {
    this.getUsers();
  },
};
</script>

<style lang="scss" scoped></style>
