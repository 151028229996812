<template>
  <all />
</template>

<script>
import all from "@/views/employees/all.vue";
export default {
  components: {
    all,
  },
};
</script>

<style lang="scss" scoped></style>
